import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import Home from "./views/Home";
import Profile from "./views/Profile";
import MainNav from "./components/NavBar/MainNav";
import Account from "./views/Account";
import AuthNavBar from "./components/NavBar/AuthNavBar";
import UnAuthNavBar from "./components/NavBar/UnAuthNavBar";
import { useAuth0 } from "@auth0/auth0-react";
import Contact from "./views/Contact";
import Features from "./views/Features";
import Company from "./views/Company";

function App() {
  const { isAuthenticated, isLoading, error } = useAuth0();
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <div className="App">
      <nav>{isAuthenticated ? <AuthNavBar /> : <UnAuthNavBar />}</nav>

      <Routes>
        {!isAuthenticated && <Route path="/" element={<Home />} />}
        {!isAuthenticated && <Route path="/contact" element={<Contact />} />}
        {!isAuthenticated && <Route path="/features" element={<Features />} />}
        {!isAuthenticated && <Route path="/company" element={<Company />} />}
        {isAuthenticated && <Route path="/profile" element={<Profile />} />}
      </Routes>
    </div>
  );
}

export default App;
