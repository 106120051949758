import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const people = [
  {
    id: 1,
    name: "Australia",
    avatar:
      "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 2,
    name: "United States",
    avatar:
      "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 3,
    name: "Mexico",
    avatar:
      "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
  },
];

export default function Account() {
  const [name, setName] = useState("");
  const [givenName, setGivenName] = useState("");
  const [birthTown, setBirthTown] = useState("");
  const [birthSuburb, setBirthSuburb] = useState("");
  const [birthState, setBirthState] = useState("");
  const [selected, setSelected] = useState(people[1]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [savingLoader, setSavingLoader] = useState(false);
  const { user } = useAuth0();

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(null);
    setSavingLoader(true);

    const axiosConfig = {
      headers: {
        Accept: "application/json",
      },
    };
    // alert(`The name you entered was: ${name}`);
    // alert(`The country you selected was: ${selected.name}`);

    const userData = {
      user_id: user.sub?.replace("|", "."),
      name: name,
      given_name: givenName,
      birth_town: birthTown,
      birth_suburb: birthSuburb,
      birth_state: birthState,
      birth_country: selected?.name,
    };

    axios
      .post(
        `https://mxt8ejgn9j.execute-api.ap-southeast-2.amazonaws.com/dev/api/v1/form/form/`,
        //`http://localhost:8000/api/v1/form/form/`,
        userData,
        axiosConfig
      )
      .then((response) => {
        console.log(response.status, response.data);
        setSavingLoader(false);
      })
      .catch((error) => {
        setSavingLoader(false);
        console.log(error);
        setError(error.message);
      });
  };

  const handleDownload = async (e) => {
    e.preventDefault();
    const user_id = user.sub?.replace("|", ".");

    try {
      const response = await axios.get(
        `https://mxt8ejgn9j.execute-api.ap-southeast-2.amazonaws.com/dev/api/v1/form/form/${user_id}`
      );

      if (response.status !== 200) {
        setLoader(false);
        console.log(error);
        setError(error.message);
        throw new Error("Network response was not ok");
      }

      setLoader(false);

      const base64Data = response.data.body;

      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const href = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = href;
      link.download = "form-80.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(href);
    } catch (error) {
      setLoader(false);
      setError(error.message);
      console.error("There was an error:", error);
    }
  };
  return (
    <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
      <form onSubmit={handleSubmit}>
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-6 border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Personal Information
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Use a permanent address where you can receive mail.
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      for="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Family name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        onChange={(e) => setName(e.target.value)}
                        autocomplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      for="given-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      All given names
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="given-name"
                        onChange={(e) => setGivenName(e.target.value)}
                        id="given-name"
                        autocomplete="family-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      for="birth-suburb"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Suburb of birth
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="birth-suburb"
                        id="birth-suburb"
                        onChange={(e) => setBirthSuburb(e.target.value)}
                        autocomplete="birth-suburb"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2 sm:col-start-1">
                    <label
                      for="birth-town"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Town/City of birth
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="birth-town"
                        id="birth-town"
                        onChange={(e) => setBirthTown(e.target.value)}
                        autocomplete="address-level2"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      for="birth-state"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      State/Province/Region
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="birth-state"
                        id="birth-state"
                        onChange={(e) => setBirthState(e.target.value)}
                        autocomplete="address-level1"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Listbox value={selected} onChange={setSelected}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                      Country
                    </Listbox.Label>
                    <div>
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                        <span className="flex items-center">
                          {/* <img
                            src={selected.avatar}
                            alt=""
                            className="h-5 w-5 flex-shrink-0 rounded-full"
                          /> */}
                          <span className="ml-3 block truncate">
                            {selected.name}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {people.map((person) => (
                            <Listbox.Option
                              key={person.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900",
                                  "relative cursor-default select-none py-2 pl-3 pr-9"
                                )
                              }
                              value={person}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center">
                                    {/* <img
                                      src={person.avatar}
                                      alt=""
                                      className="h-5 w-5 flex-shrink-0 rounded-full"
                                    /> */}
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                      {person.name}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-indigo-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <button
              disabled={savingLoader}
              type="submit"
              className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {savingLoader ? "Saving" : "Save"}
            </button>

            <button
              onClick={handleDownload}
              disabled={loader}
              className="ml-2 inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {loader ? "Downloading" : "Download"}
            </button>

            {error && <p className="text-red-500">{error}</p>}
          </div>
        </div>
      </form>
    </div>
  );
}
